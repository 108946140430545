import { handleActions } from "redux-actions";
import { IReduxAction } from "../../interfaces/redux-action";
import {
  GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS,
  GET_ALL_PAGES_CONTENT_LIST_SUCCESS
} from "../../constants/page-content.constants";
import {
  IPageContentItem,
  IPagesContentList
} from "../../../interfaces/page-content";
import { IGalleryItem } from "../../../interfaces/gallery";
import { IReduxWelcomePageContent } from "../../interfaces/content/welcome-page-content";

const getAllPagesContentListSuccess = (
  state: IReduxWelcomePageContent,
  { payload }: IReduxAction<IPagesContentList>
) => {
  const pathPrefix = "/gallery/";

  const introBannerPageContent: IPageContentItem = payload.items.find(item => {
    if (item.path.startsWith(pathPrefix)) {
      const data = JSON.parse(item.data);
      return data.type === "banners" && data.name === "welcome-intro";
    }
    return false;
  });

  const introBanner: IGalleryItem = introBannerPageContent
    ? JSON.parse(introBannerPageContent.data)
    : null;

  const promoBannerPageContent: IPageContentItem = payload.items.find(item => {
    if (item.path.startsWith(pathPrefix)) {
      const data = JSON.parse(item.data);
      return data.type === "banners" && data.name === "welcome-promo";
    }
    return false;
  });

  const promoBanner: IGalleryItem = promoBannerPageContent
    ? JSON.parse(promoBannerPageContent.data)
    : null;

  return Object.assign({}, state, {
    introBanner: {
      inProcess: false,
      hasErrors: false,
      items: introBanner
        ? introBanner.mediaItems.map(item => item.filePath)
        : state.introBanner.items
    },
    promoBanner: {
      inProcess: false,
      hasErrors: false,
      items: promoBanner
        ? promoBanner.mediaItems.map(item => item.filePath)
        : state.promoBanner.items
    }
  });
};

export const welcomePageContent = handleActions(
  {
    [GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS]: getAllPagesContentListSuccess,
    [GET_ALL_PAGES_CONTENT_LIST_SUCCESS]: getAllPagesContentListSuccess
  },
  null
);
