import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { IAppState } from "../redux/state/app-state.interface";
import { NgRedux } from "@angular-redux/store";
import { catchError, map, tap } from "rxjs/operators";
import { SubmitContentService } from "./submit-content.service";
import { IUpdateAccountData, IUserItem } from "../interfaces/user";
import {
  updateCurrentUserError,
  updateCurrentUserRequest,
  updateCurrentUserSuccess
} from "../redux/actions/user.actions";
import { normalize } from "normalizr";
import { userSchema } from "../normalizr/schemas/user.schema";

@Injectable({
  providedIn: "root"
})
export class UserService {
  constructor(
    private redux: NgRedux<IAppState>,
    private submitContentService: SubmitContentService
  ) {}

  createUserInConnect(username: string): Observable<void> {
    return this.submitContentService
      .submit("/fanclub/create-user", {
        username
      })
      .pipe(map(() => null));
  }

  updateCurrentUser(data: IUpdateAccountData): Observable<IUserItem> {
    this.redux.dispatch(updateCurrentUserRequest());
    return this.submitContentService
      .submit("/fanclub/update-user", {
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName
      })
      .pipe(
        tap(res => {
          debugger;
        }),
        map(res => res.updateUser),
        tap((user: IUserItem) => {
          const normalizrUser = normalize(user, userSchema);
          this.redux.dispatch(updateCurrentUserSuccess(normalizrUser));
        }),
        catchError(err => {
          this.redux.dispatch(updateCurrentUserError(err));
          return throwError(err);
        })
      );
  }

  submitJoinForm1(username: string, recaptcha: string): Observable<void> {
    return of(null);

    // return this.submitContentService
    //   .submit("/fanclub/join-form-1", {
    //     email: username,
    //     recaptcha,
    //     termsAgree: true
    //   })
    //   .pipe(map(() => null));
  }
}
