import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { Observable } from "rxjs";
import { IUserItem } from "../interfaces/user";
import { AuthService } from "../services/auth.service";
import { map } from "rxjs/operators";

const debug = require("debug")("aerosmith:authenticatedGuard");

@Injectable({
  providedIn: "root"
})
export class AuthenticatedGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.getCachedUser().pipe(
      map((user: IUserItem | null) => {
        if (!user) {
          debug(`locked route: ${state.url}, redirect to /login`);
          this.router.navigate([
            "/login",
            {
              callbackUrl: state.url
            }
          ]);
          return false;
        }

        return true;
      })
    );
  }
}
