import { handleActions } from "redux-actions";
import { IReduxAction } from "../interfaces/redux-action";
import {
  INIT_CURRENT_USER_ERROR,
  LOGOUT_SUCCESS
} from "../constants/auth.constants";
import {
  GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS,
  GET_ALL_PAGES_CONTENT_LIST_SUCCESS
} from "../constants/page-content.constants";
import { IPagesContentList } from "../../interfaces/page-content";
import { IUserItem } from "../../interfaces/user";

const initCurrentUserError = (state: boolean, { payload }: IReduxAction<any>) =>
  null;

const getAllPagesContentListSuccess = (
  state: Record<string, string | null>,
  { payload }: IReduxAction<IPagesContentList>
) => {
  const pathPrefix = "/user/";

  const item: IUserItem = payload.items.find(item =>
    item.path.startsWith(pathPrefix)
  );

  if (item) {
    return item.path;
  }

  return state;
};

const logoutSuccess = (state: boolean, { payload }: IReduxAction<void>) => null;

export const userId = handleActions(
  {
    [INIT_CURRENT_USER_ERROR]: initCurrentUserError,
    [LOGOUT_SUCCESS]: logoutSuccess,
    [GET_ALL_PAGES_CONTENT_LIST_SUCCESS]: getAllPagesContentListSuccess,
    [GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS]: getAllPagesContentListSuccess
  },
  null
);
