import { createAction } from "redux-actions";
import {
  CONFIRM_SIGN_UP_ERROR,
  CONFIRM_SIGN_UP_REQUEST,
  CONFIRM_SIGN_UP_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUBMIT_ERROR,
  FORGOT_PASSWORD_SUBMIT_REQUEST,
  FORGOT_PASSWORD_SUBMIT_SUCCESS,
  FORGOT_PASSWORD_SUCCESS,
  INIT_CURRENT_USER_ERROR,
  INIT_CURRENT_USER_REQUEST,
  INIT_CURRENT_USER_SUCCESS,
  LOGOUT_ERROR,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  RESEND_SIGN_UP_ERROR,
  RESEND_SIGN_UP_REQUEST,
  RESEND_SIGN_UP_SUCCESS,
  SIGN_IN_ERROR,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_UP_ERROR,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS
} from "../constants/auth.constants";

// init current user (begin)
export const initCurrentUserRequest = () =>
  createAction(INIT_CURRENT_USER_REQUEST)();

export const initCurrentUserSuccess = (isAuthenticated: boolean) =>
  createAction(INIT_CURRENT_USER_SUCCESS)(isAuthenticated);

export const initCurrentUserError = (err: any) =>
  createAction(INIT_CURRENT_USER_ERROR)(err);
// init current user (end)

// sign in (begin)
export const signInRequest = () => createAction(SIGN_IN_REQUEST)();

export const signInSuccess = () => createAction(SIGN_IN_SUCCESS)();

export const signInError = (err: any) => createAction(SIGN_IN_ERROR)(err);
// sign in (end)

// sign up (begin)
export const signUpRequest = () => createAction(SIGN_UP_REQUEST)();

export const signUpSuccess = () => createAction(SIGN_UP_SUCCESS)();

export const signUpError = (err: any) => createAction(SIGN_UP_ERROR)(err);
// sign up (end)

// confirm sign up (begin)
export const confirmSignUpRequest = () =>
  createAction(CONFIRM_SIGN_UP_REQUEST)();

export const confirmSignUpSuccess = () =>
  createAction(CONFIRM_SIGN_UP_SUCCESS)();

export const confirmSignUpError = (err: any) =>
  createAction(CONFIRM_SIGN_UP_ERROR)(err);
// confirm sign up (end)

// resend sign up (begin)
export const resendSignUpRequest = () => createAction(RESEND_SIGN_UP_REQUEST)();

export const resendSignUpSuccess = () => createAction(RESEND_SIGN_UP_SUCCESS)();

export const resendSignUpError = (err: any) =>
  createAction(RESEND_SIGN_UP_ERROR)(err);
// resend sign up (end)

// forgot password (begin)
export const forgotPasswordRequest = () =>
  createAction(FORGOT_PASSWORD_REQUEST)();

export const forgotPasswordSuccess = () =>
  createAction(FORGOT_PASSWORD_SUCCESS)();

export const forgotPasswordError = (err: any) =>
  createAction(FORGOT_PASSWORD_ERROR)(err);
// forgot password (end)

// forgot password submit (begin)
export const forgotPasswordSubmitRequest = () =>
  createAction(FORGOT_PASSWORD_SUBMIT_REQUEST)();

export const forgotPasswordSubmitSuccess = () =>
  createAction(FORGOT_PASSWORD_SUBMIT_SUCCESS)();

export const forgotPasswordSubmitError = (err: any) =>
  createAction(FORGOT_PASSWORD_SUBMIT_ERROR)(err);
// forgot password submit (end)

// logout (begin)
export const logoutRequest = () => createAction(LOGOUT_REQUEST)();

export const logoutSuccess = () => createAction(LOGOUT_SUCCESS)();

export const logoutError = (err: any) => createAction(LOGOUT_ERROR)(err);
// logout (end)
