const fcmConfig = {
  apiKey: "AIzaSyCe-s_FY-2sAJh8sbl4sXpPQb5_hcFGjj8",
  authDomain: "margaritavilleuniversity-pwa.firebaseapp.com",
  databaseURL: "https://margaritavilleuniversity-pwa.firebaseio.com",
  projectId: "margaritavilleuniversity-pwa",
  storageBucket: "margaritavilleuniversity-pwa.appspot.com",
  messagingSenderId: "518781499154"
};

export default fcmConfig;
