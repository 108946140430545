import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { AmplifyAngularModule, AmplifyService } from "aws-amplify-angular";
import { appInitializer } from "./utils/app-initializer";
import { NgReduxModule } from "@angular-redux/store";
import { IonicStorageModule } from "@ionic/storage";
import { AuthService } from "./services/auth.service";
import { HttpClientModule } from "@angular/common/http";
import { initialAppState } from "./redux/state/initial-app-state";
import { rootReducer } from "./redux/reducers";
import {
  NGX_REDUX_CONFIG_TOKEN,
  NgxReduxModule,
  StoreConfiguratorService
} from "@bubbleup/ngx-redux";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AmplifyAngularModule,
    IonicStorageModule.forRoot(),
    NgReduxModule,
    HttpClientModule,
    NgxReduxModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [StoreConfiguratorService, AuthService],
      multi: true
    },
    AmplifyService,
    {
      provide: NGX_REDUX_CONFIG_TOKEN,
      useValue: {
        rootReducer,
        initialAppState
      }
    },
    InAppBrowser
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
