import { environment } from "../../environments/environment";
import { AuthService } from "../services/auth.service";
import { flatMap } from "rxjs/operators";
import { StoreConfiguratorService } from "@bubbleup/ngx-redux";

export const appInitializer = (
  storeConfigurator: StoreConfiguratorService,
  authService: AuthService
) => () =>
  storeConfigurator
    .configureStore(environment.production)
    .pipe(flatMap(() => authService.initCurrentUser()))
    .toPromise();
