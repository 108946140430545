import { Component } from "@angular/core";

import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { PushNotificationService } from "./services/push-notification.service";
import { TotalApiService } from "./services/total-api.service";
import { Event, NavigationEnd, Scroll, Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html"
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private pushNotificationService: PushNotificationService,
    private totalApiService: TotalApiService,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is("cordova")) {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
      }
    });

    this.pushNotificationService.initSubscription();

    this.totalApiService.getAllPagesContentList().subscribe();

    let prevUrl: string | null = null;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (
          prevUrl !== null &&
          event.urlAfterRedirects.split("#")[0] !== prevUrl
        ) {
          const container: any = document.querySelector(
            ".ion-page:not(ion-app):not(.ion-page-hidden) main"
          );
          if (container) {
            container.setAttribute("tabindex", "-1");
            container.focus();
          }
        }

        prevUrl = event.urlAfterRedirects.split("#")[0];
      }
    });
  }
}
