import { handleActions } from "redux-actions";
import { IReduxAction } from "../../interfaces/redux-action";
import {
  GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS,
  GET_ALL_PAGES_CONTENT_LIST_SUCCESS
} from "../../constants/page-content.constants";
import { INormalizrMediaEntity } from "../../../normalizr/interfaces/media";
import { normalize } from "normalizr";
import { IPagesContentList } from "../../../interfaces/page-content";
import { INormalizrNavigationMenusList } from "../../../normalizr/interfaces/navigation-menu";
import { navigationMenuSchema } from "../../../normalizr/schemas/navigation-menu.schema";
import { INavigationMenuItem } from "../../../interfaces/navigation-menu";

const getAllPagesContentListSuccess = (
  state: Record<string, INormalizrMediaEntity>,
  { payload }: IReduxAction<IPagesContentList>
) => {
  const pathPrefix = "/menu/";

  const items: INavigationMenuItem[] = payload.items
    .filter(item => item.path.startsWith(pathPrefix))
    .map(item => JSON.parse(item.data));
  /*.map(item => ({
      path: item.path,
      ...JSON.parse(item.data)
    }));*/

  const normalizrItems: INormalizrNavigationMenusList = normalize(
    {
      items
    },
    {
      items: [navigationMenuSchema]
    }
  );

  return Object.assign({}, state, normalizrItems.entities.navigationMenus);
};

export const navigationMenuEntities = handleActions(
  {
    [GET_ALL_PAGES_CONTENT_LIST_SUCCESS]: getAllPagesContentListSuccess,
    [GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS]: getAllPagesContentListSuccess
  },
  null
);
