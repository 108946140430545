import { Injectable } from "@angular/core";
import { IAppState } from "../redux/state/app-state.interface";
import {
  Observable,
  from,
  throwError,
  of,
  EMPTY,
  interval,
  Subject
} from "rxjs";
import { catchError, tap, expand, takeUntil } from "rxjs/operators";
import { NgRedux } from "@angular-redux/store";
import { IPagesContentList } from "../interfaces/page-content";
import {
  getAllPagesContentListError,
  getAllPagesContentListFirstChunkSuccess,
  getAllPagesContentListRequest,
  getAllPagesContentListSuccess
} from "../redux/actions/page-content.actions";
import { AmplifyService } from "aws-amplify-angular";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class TotalApiService {
  private apiCallInProcess$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private amplifyService: AmplifyService,
    private redux: NgRedux<IAppState>
  ) {}

  getAllPagesContentList(): Observable<any> {
    this.apiCallInProcess$.next(true);

    this.redux.dispatch(getAllPagesContentListRequest());
    return from(this.callApi(environment.siteId, null)).pipe(
      takeUntil(this.apiCallInProcess$),
      expand((pagesContentList: IPagesContentList, index: number) => {
        if (index === 0) {
          this.redux.dispatch(
            getAllPagesContentListFirstChunkSuccess(pagesContentList)
          );
        } else {
          this.redux.dispatch(getAllPagesContentListSuccess(pagesContentList));
        }

        if (pagesContentList.nextToken) {
          return from(
            this.callApi(environment.siteId, pagesContentList.nextToken)
          ).pipe(takeUntil(this.apiCallInProcess$));
        } else {
          return EMPTY;
        }
      }),
      catchError(err => {
        this.redux.dispatch(getAllPagesContentListError(err));
        return throwError(err);
      })
    );
  }

  private async callApi(
    siteId: string,
    nextToken: string | null
  ): Promise<any> {
    const statement = `query listAllSiteContent($siteId: ID!, $nextToken: String) { 
      listAllSiteContent(siteId: $siteId, nextToken: $nextToken) {
        items{
          path
          pathAliases
          tags
          data
        },
        nextToken
      }
    }
    `;

    const api = this.amplifyService.api();

    const gqlAPIServiceArguments: any = {
      siteId,
      nextToken
    };

    const response = (await api.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;

    return response.data.listAllSiteContent;
  }
}
