import { schema } from "normalizr";
import { mediaSchema } from "./media.schema";

export const gallerySchema = new schema.Entity(
  "galleries",
  {
    mediaItems: [mediaSchema]
  },
  {
    idAttribute: "path"
  }
);
