import {
  INormalizrConcertEntity,
  INormalizrConcertsList
} from "../../../normalizr/interfaces/concert";
import { handleActions } from "redux-actions";
import { IReduxAction } from "../../interfaces/redux-action";
import {
  GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS,
  GET_ALL_PAGES_CONTENT_LIST_SUCCESS
} from "../../constants/page-content.constants";
import { IPagesContentList } from "../../../interfaces/page-content";
import { normalize } from "normalizr";
import { IGalleryItem } from "../../../interfaces/gallery";
import { concertSchema } from "../../../normalizr/schemas/concert.schema";

const getAllPagesContentListSuccess = (
  state: Record<string, INormalizrConcertEntity>,
  { payload }: IReduxAction<IPagesContentList>
) => {
  const pathPrefix = "/events/tour/";

  const items: IGalleryItem[] = payload.items
    .filter(item => item.path.startsWith(pathPrefix))
    .map(item => ({
      path: item.path,
      ...JSON.parse(item.data)
    }));

  const normalizrItems: INormalizrConcertsList = normalize(
    {
      items
    },
    {
      items: [concertSchema]
    }
  );

  return Object.assign({}, state, normalizrItems.entities.concerts);
};

export const concertEntities = handleActions(
  {
    [GET_ALL_PAGES_CONTENT_LIST_SUCCESS]: getAllPagesContentListSuccess,
    [GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS]: getAllPagesContentListSuccess
  },
  null
);
