import { createAction } from "redux-actions";
import {
  GET_CURRENT_USER_ERROR,
  GET_CURRENT_USER_REQUEST,
  GET_CURRENT_USER_SUCCESS,
  RENEW_MEMBERSHIP_ERROR,
  RENEW_MEMBERSHIP_REQUEST,
  RENEW_MEMBERSHIP_SUCCESS,
  SUBMIT_JOIN_FORM2_ERROR,
  SUBMIT_JOIN_FORM2_REQUEST,
  SUBMIT_JOIN_FORM2_SUCCESS,
  UPDATE_CURRENT_USER_ERROR,
  UPDATE_CURRENT_USER_REQUEST,
  UPDATE_CURRENT_USER_SUCCESS
} from "../constants/user.constants";
import { INormalizrUserItem } from "../../normalizr/interfaces/user";

// get current user (begin)
export const getCurrentUserRequest = () =>
  createAction(GET_CURRENT_USER_REQUEST)();

export const getCurrentUserSuccess = (user: INormalizrUserItem) =>
  createAction(GET_CURRENT_USER_SUCCESS)(user);

export const getCurrentUserError = (err: any) =>
  createAction(GET_CURRENT_USER_ERROR)(err);
// get current user (end)

// submit join form 2 (begin)
export const submitJoinForm2Request = () =>
  createAction(SUBMIT_JOIN_FORM2_REQUEST)();

export const submitJoinForm2Success = (user: INormalizrUserItem) =>
  createAction(SUBMIT_JOIN_FORM2_SUCCESS)(user);

export const submitJoinForm2Error = (err: any) =>
  createAction(SUBMIT_JOIN_FORM2_ERROR)(err);
// submit join form 2 (end)

// update current user (begin)
export const updateCurrentUserRequest = () =>
  createAction(UPDATE_CURRENT_USER_REQUEST)();

export const updateCurrentUserSuccess = (user: INormalizrUserItem) =>
  createAction(UPDATE_CURRENT_USER_SUCCESS)(user);

export const updateCurrentUserError = (err: any) =>
  createAction(UPDATE_CURRENT_USER_ERROR)(err);
// update current user (end)

// renew membership (begin)
export const renewMembershipRequest = () =>
  createAction(RENEW_MEMBERSHIP_REQUEST)();

export const renewMembershipSuccess = (user: INormalizrUserItem) =>
  createAction(RENEW_MEMBERSHIP_SUCCESS)(user);

export const renewMembershipError = (err: any) =>
  createAction(RENEW_MEMBERSHIP_ERROR)(err);
// renew membership (end)
