import { IAppState } from "./app-state.interface";

export const initialAppState: IAppState = {
  cacheName: "cache14",
  recaptchaSiteKey: "6LfLxBAUAAAAAHo8SxB-xcyioKquq3vNDT_50Fs2",
  isAuthenticated: false,
  userId: null,
  content: {
    newsPage: {
      items: [],
      inProcess: true,
      hasErrors: false
    },
    tourPage: {
      items: [],
      inProcess: true,
      hasErrors: false
    },
    galleriesPage: {
      items: [],
      inProcess: true,
      hasErrors: false
    },
    videosPage: {
      items: [],
      inProcess: true,
      hasErrors: false
    },
    welcomePage: {
      introBanner: {
        items: [],
        inProcess: true,
        hasErrors: false
      },
      promoBanner: {
        items: [],
        inProcess: true,
        hasErrors: false
      }
    },
    cart: {
      id: ""
    }
  },
  entities: {
    news: {},
    concerts: {},
    galleries: {},
    medias: {},
    navigationMenus: {},
    simplePages: {},
    users: {},
    cart: {}
  }
};
