import { combineReducers } from "redux";
import { newsPageContent } from "./content/news-page-content.reducer";
import { tourPageContent } from "./content/tour-page-content.reducer";
import { galleriesPageContent } from "./content/galleries-page-content.reducer";
import { videosPageContent } from "./content/videos-page-content.reducer";
import { welcomePageContent } from "./content/welcome-page-content.reducer";
import { cartContent } from "./content/cart-content.reducer";

export const content = combineReducers<any>({
  newsPage: newsPageContent,
  tourPage: tourPageContent,
  galleriesPage: galleriesPageContent,
  videosPage: videosPageContent,
  welcomePage: welcomePageContent,
  cart: cartContent
});
