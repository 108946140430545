import { Injectable } from "@angular/core";
import { AmplifyService } from "aws-amplify-angular";
import { Observable, from, throwError, of } from "rxjs";
import { catchError, tap, map, flatMap } from "rxjs/operators";
import { denormalize } from "normalizr";
import { ISignInData } from "../interfaces/sign-in";
import { ISignUpData } from "../interfaces/sign-up";
import { IUserItem } from "../interfaces/user";
import { IConfirmSignUpData } from "../interfaces/confirm";
import { IAppState } from "../redux/state/app-state.interface";
import { NgRedux } from "@angular-redux/store";
import {
  confirmSignUpError,
  confirmSignUpRequest,
  confirmSignUpSuccess,
  forgotPasswordError,
  forgotPasswordRequest,
  forgotPasswordSubmitError,
  forgotPasswordSubmitRequest,
  forgotPasswordSubmitSuccess,
  forgotPasswordSuccess,
  initCurrentUserError,
  initCurrentUserRequest,
  initCurrentUserSuccess,
  logoutError,
  logoutRequest,
  logoutSuccess,
  resendSignUpError,
  resendSignUpRequest,
  resendSignUpSuccess,
  signInError,
  signInRequest,
  signInSuccess,
  signUpError,
  signUpRequest,
  signUpSuccess
} from "../redux/actions/auth.actions";
import { userSchema } from "../normalizr/schemas/user.schema";
import { UserService } from "./user.service";
import { IReduxEntities } from "../redux/interfaces/entities";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  constructor(
    private amplifyService: AmplifyService,
    private redux: NgRedux<IAppState>,
    private userService: UserService
  ) {}

  isAuthenticated(): Observable<boolean> {
    const state: IAppState = this.redux.getState();

    return of(state.isAuthenticated);
  }

  getCachedUser(): Observable<IUserItem | null> {
    const state: IAppState = this.redux.getState();

    const userId: string | null = state.userId;
    const entities: IReduxEntities = state.entities;

    const user: IUserItem | null = denormalize(userId, userSchema, entities);
    return of(user);
  }

  initCurrentUser() {
    const auth = this.amplifyService.auth();

    this.redux.dispatch(initCurrentUserRequest());

    return from(auth.currentAuthenticatedUser()).pipe(
      tap(() => {
        this.redux.dispatch(initCurrentUserSuccess(true));
      }),
      catchError(err => {
        if (err === "not authenticated") {
          this.redux.dispatch(initCurrentUserSuccess(false));
          return of(null);
        } else {
          this.redux.dispatch(initCurrentUserError(err));
          return throwError(err);
        }
      })
    );
  }

  signIn(data: ISignInData): Observable<IUserItem> {
    const auth = this.amplifyService.auth();

    this.redux.dispatch(signInRequest());
    return from(auth.signIn(data.email, data.password)).pipe(
      flatMap(res => this.userService.createUserInConnect(data.email)),
      map(() => null),
      tap(() => {
        this.redux.dispatch(signInSuccess());
      }),
      catchError(err => {
        this.redux.dispatch(signInError(err));
        return throwError(err);
      })
    );
  }

  signUp(data: ISignUpData): Observable<void> {
    const auth = this.amplifyService.auth();

    this.redux.dispatch(signUpRequest());
    return this.userService.submitJoinForm1(data.email, data.recaptcha).pipe(
      flatMap(() =>
        from(
          auth.signUp({
            username: data.email,
            password: data.password
          })
        )
      ),
      map(() => null),
      tap(() => this.redux.dispatch(signUpSuccess())),
      catchError(err => {
        this.redux.dispatch(signUpError(err));
        return throwError(err);
      })
    );
  }

  confirmSignUp(data: IConfirmSignUpData): Observable<void> {
    const auth = this.amplifyService.auth();

    this.redux.dispatch(confirmSignUpRequest());

    return from(auth.confirmSignUp(data.username, data.code)).pipe(
      map(() => null),
      tap(() => this.redux.dispatch(confirmSignUpSuccess())),
      catchError(err => {
        this.redux.dispatch(confirmSignUpError(err));
        return throwError(err);
      })
    );
  }

  resendSignUp(username: string): Observable<void> {
    const auth = this.amplifyService.auth();

    this.redux.dispatch(resendSignUpRequest());

    return from(auth.resendSignUp(username)).pipe(
      map(() => null),
      tap(() => this.redux.dispatch(resendSignUpSuccess())),
      catchError(err => {
        this.redux.dispatch(resendSignUpError(err));
        return throwError(err);
      })
    );
  }

  forgotPassword(username: string): Observable<void> {
    const auth = this.amplifyService.auth();

    this.redux.dispatch(forgotPasswordRequest());

    return from(auth.forgotPassword(username)).pipe(
      map(() => null),
      tap(() => this.redux.dispatch(forgotPasswordSuccess())),
      catchError(err => {
        this.redux.dispatch(forgotPasswordError(err));
        return throwError(err);
      })
    );
  }

  forgotPasswordSubmit(
    username: string,
    code: string,
    password: string
  ): Observable<void> {
    const auth = this.amplifyService.auth();

    this.redux.dispatch(forgotPasswordSubmitRequest());

    return from(auth.forgotPasswordSubmit(username, code, password)).pipe(
      map(() => null),
      tap(() => this.redux.dispatch(forgotPasswordSubmitSuccess())),
      catchError(err => {
        this.redux.dispatch(forgotPasswordSubmitError(err));
        return throwError(err);
      })
    );
  }

  logout(): Observable<void> {
    const auth = this.amplifyService.auth();

    this.redux.dispatch(logoutRequest());

    return from(auth.signOut()).pipe(
      map(() => null),
      tap(() => this.redux.dispatch(logoutSuccess())),
      catchError(err => {
        this.redux.dispatch(logoutError(err));
        return throwError(err);
      })
    );
  }
}
