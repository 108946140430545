// get current user (begin)
export const GET_CURRENT_USER_REQUEST = "GET_CURRENT_USER_REQUEST";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";
export const GET_CURRENT_USER_ERROR = "GET_CURRENT_USER_ERROR";
// get current user (end)

// submit join form 2 (begin)
export const SUBMIT_JOIN_FORM2_REQUEST = "SUBMIT_JOIN_FORM2_REQUEST";
export const SUBMIT_JOIN_FORM2_SUCCESS = "SUBMIT_JOIN_FORM2_SUCCESS";
export const SUBMIT_JOIN_FORM2_ERROR = "SUBMIT_JOIN_FORM2_ERROR";
// submit join form 2 (end)

// update current user (begin)
export const UPDATE_CURRENT_USER_REQUEST = "UPDATE_CURRENT_USER_REQUEST";
export const UPDATE_CURRENT_USER_SUCCESS = "UPDATE_CURRENT_USER_SUCCESS";
export const UPDATE_CURRENT_USER_ERROR = "UPDATE_CURRENT_USER_ERROR";
// update current user (end)

// renew membership (begin)
export const RENEW_MEMBERSHIP_REQUEST = "RENEW_MEMBERSHIP_REQUEST";
export const RENEW_MEMBERSHIP_SUCCESS = "RENEW_MEMBERSHIP_SUCCESS";
export const RENEW_MEMBERSHIP_ERROR = "RENEW_MEMBERSHIP_ERROR";
// renew membership (end)
