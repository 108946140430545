import { handleActions } from "redux-actions";
import { IReduxAction } from "../../interfaces/redux-action";
import { IReduxListContent } from "../../interfaces/content/list-content";
import {
  GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS,
  GET_ALL_PAGES_CONTENT_LIST_SUCCESS
} from "../../constants/page-content.constants";
import { IPagesContentList } from "../../../interfaces/page-content";

const getConcertIds = (payload: IPagesContentList): string[] => {
  const pathPrefix = "/events/tour/";
  return payload.items
    .filter(item => item.path.startsWith(pathPrefix))
    .map(item => item.path);
};

const getAllPagesContentListFirstChunkSuccess = (
  state: IReduxListContent,
  { payload }: IReduxAction<IPagesContentList>
) => {
  const ids: string[] = getConcertIds(payload);

  return Object.assign({}, state, {
    inProcess: false,
    hasErrors: false,
    items: ids
  });
};

const getAllPagesContentListSuccess = (
  state: IReduxListContent,
  { payload }: IReduxAction<IPagesContentList>
) => {
  const ids: string[] = getConcertIds(payload);

  const inProcess: boolean = payload.nextToken ? true : false;

  return Object.assign({}, state, {
    inProcess,
    items: [...state.items, ...ids]
  });
};

export const tourPageContent = handleActions(
  {
    [GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS]: getAllPagesContentListFirstChunkSuccess,
    [GET_ALL_PAGES_CONTENT_LIST_SUCCESS]: getAllPagesContentListSuccess
  },
  null
);
