import { handleActions } from "redux-actions";
import {
  INormalizrUserEntity,
  INormalizrUserItem,
  INormalizrUsersList
} from "../../../normalizr/interfaces/user";
import { IReduxAction } from "../../interfaces/redux-action";
import {
  GET_CURRENT_USER_SUCCESS,
  UPDATE_CURRENT_USER_SUCCESS
} from "../../constants/user.constants";
import {
  GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS,
  GET_ALL_PAGES_CONTENT_LIST_SUCCESS
} from "../../constants/page-content.constants";
import { INormalizrNewsEntity } from "../../../normalizr/interfaces/news";
import { IPagesContentList } from "../../../interfaces/page-content";
import { normalize } from "normalizr";
import { IUserItem } from "../../../interfaces/user";
import { userSchema } from "../../../normalizr/schemas/user.schema";

const getCurrentUserSuccess = (
  state: Record<string, INormalizrUserEntity>,
  { payload }: IReduxAction<INormalizrUserItem | null>
) => (payload ? Object.assign({}, state, payload.entities.users) : state);

const updateCurrentUserSuccess = (
  state: Record<string, INormalizrUserEntity>,
  { payload }: IReduxAction<INormalizrUserItem>
) => Object.assign({}, state, payload.entities.users);

const getAllPagesContentListSuccess = (
  state: Record<string, INormalizrNewsEntity>,
  { payload }: IReduxAction<IPagesContentList>
) => {
  const pathPrefix = "/user/";

  const items: IUserItem[] = payload.items
    .filter(item => item.path.startsWith(pathPrefix))
    .map(item => ({
      path: item.path,
      ...JSON.parse(item.data)
    }));

  const normalizrItems: INormalizrUsersList = normalize(
    {
      items
    },
    {
      items: [userSchema]
    }
  );

  return Object.assign({}, state, normalizrItems.entities.users);
};

export const userEntities = handleActions(
  {
    [GET_CURRENT_USER_SUCCESS]: getCurrentUserSuccess,
    [UPDATE_CURRENT_USER_SUCCESS]: updateCurrentUserSuccess,
    [GET_ALL_PAGES_CONTENT_LIST_SUCCESS]: getAllPagesContentListSuccess,
    [GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS]: getAllPagesContentListSuccess
  },
  null
);
