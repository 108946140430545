import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthenticatedGuard } from "./guards/authenticated.guard";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "/welcome"
  },
  {
    path: "welcome",
    loadChildren: "./pages/welcome/welcome.module#WelcomePageModule"
  },
  { path: "news", loadChildren: "./pages/news/news.module#NewsPageModule" },
  { path: "tour", loadChildren: "./pages/tour/tour.module#TourPageModule" },
  {
    path: "videos",
    loadChildren: "./pages/videos/videos.module#VideosPageModule"
  },
  {
    path: "photos",
    loadChildren: "./pages/photos/photos.module#PhotosPageModule"
  },
  {
    path: "fanclub",
    loadChildren: "./pages/fanclub/fanclub.module#FanclubPageModule"
  },
  {
    path: "email-updates",
    loadChildren:
      "./pages/email-updates/email-updates.module#EmailUpdatesPageModule"
  },
  {
    path: "privacy",
    loadChildren: "./pages/privacy/privacy.module#PrivacyPageModule"
  },
  { path: "terms", loadChildren: "./pages/terms/terms.module#TermsPageModule" },
  {
    path: "news-details",
    loadChildren:
      "./pages/news-details/news-details.module#NewsDetailsPageModule"
  },
  { path: "login", loadChildren: "./pages/login/login.module#LoginPageModule" },
  {
    path: "tickets",
    loadChildren: "./pages/tickets/tickets.module#TicketsPageModule",
    canActivate: [AuthenticatedGuard]
  },
  { path: "vlog", loadChildren: "./pages/vlog/vlog.module#VlogPageModule" },
  {
    path: "contests",
    loadChildren: "./pages/contests/contests.module#ContestsPageModule"
  },
  {
    path: "galleries",
    loadChildren: "./pages/galleries/galleries.module#GalleriesPageModule"
  },
  {
    path: "page404",
    loadChildren: "./pages/page404/page404.module#Page404PageModule"
  },
  {
    path: "email-updates-thank-you",
    loadChildren:
      "./pages/email-updates-thank-you/email-updates-thank-you.module#EmailUpdatesThankYouPageModule"
  },
  { path: "join", loadChildren: "./pages/join/join.module#JoinPageModule" },
  {
    path: "confirm-sign-up",
    loadChildren:
      "./pages/confirm-sign-up/confirm-sign-up.module#ConfirmSignUpPageModule"
  },
  { path: "join", loadChildren: "./pages/join/join.module#JoinPageModule" },
  {
    path: "store",
    pathMatch: "full",
    redirectTo: "/store/welcome"
  },
  {
    path: "store/welcome",
    loadChildren:
      "./pages/store/store-home/store-home.module#StoreHomePageModule"
  },
  {
    path: "store/cart",
    loadChildren:
      "./pages/store/store-cart/store-cart.module#StoreCartPageModule"
  },
  {
    path: "store/category",
    loadChildren:
      "./pages/store/store-category/store-category.module#StoreCategoryPageModule"
  },
  {
    path: "store/product",
    loadChildren:
      "./pages/store/store-product/store-product.module#StoreProductPageModule"
  },
  {
    path: "store/checkout",
    loadChildren:
      "./pages/store/store-checkout/store-checkout.module#StoreCheckoutPageModule"
  },
  {
    path: "forgot-password",
    loadChildren:
      "./pages/forgot-password/forgot-password.module#ForgotPasswordPageModule"
  },
  {
    path: "forgot-password-submit-step1",
    loadChildren:
      "./pages/forgot-password-submit-step1/forgot-password-submit-step1.module#ForgotPasswordSubmitStep1PageModule"
  },
  {
    path: "forgot-password-submit-step2",
    loadChildren:
      "./pages/forgot-password-submit-step2/forgot-password-submit-step2.module#ForgotPasswordSubmitStep2PageModule"
  },
  {
    path: "register",
    loadChildren:
      "./pages/store-register/register.module#StoreRegisterPageModule"
  },
  {
    path: "store/login",
    loadChildren: "./pages/store-login/store-login.module#StoreLoginPageModule"
  },
  {
    path: "forgotpassword",
    loadChildren:
      "./pages/store-forgot-password/forgot-password.module#StoreForgotPasswordPageModule"
  },
  // {
  //   path: "store-account",
  //   loadChildren:
  //     "./pages/store-account/store-account.module#StoreAccountPageModule"
  // },
  {
    path: "account",
    loadChildren: "./pages/account/account.module#AccountPageModule"
  },

  //store-account pages
  {
    path: "store/account",
    redirectTo: "/store/account/orders",
    pathMatch: "full"
  },
  {
    path: "store/account/orders",
    loadChildren: "./pages/store-account/orders/orders.module#OrdersPageModule"
  },
  {
    path: "store/account/reviews",
    loadChildren:
      "./pages/store-account/reviews/reviews.module#ReviewsPageModule"
  },
  {
    path: "store/account/downloadable",
    loadChildren:
      "./pages/store-account/downloadable/downloadable.module#DownloadablePageModule"
  },
  {
    path: "store/account/tickets",
    loadChildren:
      "./pages/store-account/tickets/tickets.module#TicketsPageModule"
  },
  {
    path: "store/account/gift-cards",
    loadChildren:
      "./pages/store-account/gift-cards/gift-cards.module#GiftCardsPageModule"
  },
  {
    path: "staccount",
    loadChildren:
      "./pages/store-account/account/account.module#AccountPageModule"
  },
  {
    path: "simple",
    loadChildren: "./pages/simple/simple.module#SimplePageModule"
  }
];

routes.push({
  path: "**",
  loadChildren: "./pages/simple/simple.module#SimplePageModule"
});

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
