import { Injectable } from "@angular/core";
import * as firebase from "firebase/app";
import "firebase/messaging";
import fcmConfig from "../../fcm-config.js";
import { Platform } from "@ionic/angular";

@Injectable({
  providedIn: "root"
})
export class PushNotificationService {
  constructor(private platform: Platform) {}

  initSubscription() {
    if (!("serviceWorker" in navigator)) {
      console.log("serviceWorker not supported");
      return;
    }

    const firebaseApp = firebase.initializeApp(fcmConfig);

    try {
      const fbMessaging = firebaseApp.messaging();

      navigator.serviceWorker.ready.then(serviceWorker => {
        fbMessaging.useServiceWorker(serviceWorker);

        fbMessaging
          .requestPermission()
          .then(() => firebaseApp.messaging().getToken())
          .then(token => this.saveFcmToken(token))
          // .then(() => console.log('save token ok'))
          .catch(err => {
            console.log("push notifications is blocked", err);
          });
      });
    } catch (e) {
      console.log("push notifications not supported");
    }
  }

  private saveFcmToken(token: string) {
    console.log("fcm token", token);
    // debugger;
  }
}
