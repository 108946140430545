import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";
import { map } from "rxjs/operators";
import { AmplifyService } from "aws-amplify-angular";
import { environment } from "../../environments/environment";

const siteId: string = environment.siteId;

@Injectable({
  providedIn: "root"
})
export class SubmitContentService {
  constructor(private amplifyService: AmplifyService) {}

  submit(path: string, data: any): Observable<any> {
    const statement = `mutation submitContent($data: String!, $site: ID!, $path: String!){ 
      submitContent(contentInput: {
        site: $site
        path: $path
        data: $data
      }) {
        path
        data
      }
    }
    `;

    const api = this.amplifyService.api();

    const gqlAPIServiceArguments: any = {
      site: siteId,
      data: JSON.stringify(data),
      path
    };

    return from(
      api.graphql({
        query: statement,
        variables: gqlAPIServiceArguments
      })
    ).pipe(map((item: any) => item.data.submitContent));
  }
}
