import { handleActions } from "redux-actions";
import { IReduxAction } from "../../interfaces/redux-action";
import { IReduxListContent } from "../../interfaces/content/list-content";
import {
  GET_CART_ERROR,
  GET_CART_REQUEST,
  GET_CART_SUCCESS
} from "../../constants/shopify-cart.constants";

const getCartSuccess = (
  state: Record<string, any>,
  { payload }: IReduxAction<any>
) => {
  return Object.assign({}, state, { id: payload.result });
};

const getCartReqest = (
  state: IReduxListContent,
  { payload }: IReduxAction<any>
) =>
  Object.assign({}, state, {
    inProcess: false,
    hasErrors: false,
    items: payload.result.items
  });

const getCartError = (state: IReduxListContent) =>
  Object.assign({}, state, {
    inProcess: false,
    hasErrors: true
  });

export const cartContent = handleActions(
  {
    // [GET_CART_REQUEST]: getCartRequest,
    [GET_CART_SUCCESS]: getCartSuccess
    // [GET_CART_ERROR]: getCartError
  },
  null
);
